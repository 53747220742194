@import "vendor/bower_components/font-awesome/scss/font-awesome";

//ADAM
@font-face {
  font-family: 'Adam';
  src: url('/assets/site/fonts/Adam/adam.cg_pro-webfont.eot');
  src: url('/assets/site/fonts/Adam/adam.cg_pro-webfont.eot?#iefix') format('embedded-opentype'),
  url('/assets/site/fonts/Adam/adam.cg_pro-webfont.woff2') format('woff2'),
  url('/assets/site/fonts/Adam/adam.cg_pro-webfont.woff') format('woff'),
  url('/assets/site/fonts/Adam/adam.cg_pro-webfont.ttf') format('truetype'),
  url('/assets/site/fonts/Adam/adam.cg_pro-webfont.svg#adam.cg_proregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
// Averta

@font-face {
  font-family: 'Averta';
  src: url('/assets/site/fonts/Averta/avertademo-regular-webfont.eot');
  src: url('/assets/site/fonts/Averta/avertademo-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/assets/site/fonts/Averta/avertademo-regular-webfont.woff2') format('woff2'),
  url('/assets/site/fonts/Averta/avertademo-regular-webfont.woff') format('woff'),
  url('/assets/site/fonts/Averta/avertademo-regular-webfont.ttf') format('truetype'),
  url('/assets/site/fonts/Averta/avertademo-regular-webfont.svg#averta_demoregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'infinitefont';
  src:url('/assets/site/fonts/infinite/infinitefont-webfont.eot');
  src:url('/assets/site/fonts/infinite/infinitefont-webfont.eot?#iefix') format('embedded-opentype'),
  url('/assets/site/fonts/infinite/infinitefont-webfont.woff') format('woff'),
  url('/assets/site/fonts/infinite/infinitefont-webfont.ttf') format('truetype'),
  url('/assets/site/fonts/infinite/infinitefont-webfont.svg#infinitefont') format('svg');
  font-weight: normal;
  font-style: normal;
}
