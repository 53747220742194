.btn{
  transition: all 0.4s linear;
  outline: none !important;
  text-transform: uppercase;
  border-radius: 0;
  font-family: "Adam";
  font-size: 15px;
  letter-spacing: 4px;
  padding: 10px 15px;
  &.gold-btn{
    background: $gold;
    color: #fae0c2;
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
  }
  &.btn-nocover{
    border: 1px solid $gold;
    color: $gold;
    background: transparent;

    &:hover{
      background: $gold;
      color: #fff;
    }

    &.interna {
      background: $gold;
      color: #FFF;

      &:hover{
        color: $gold;
        background: transparent;
      }
    }
  }
  &.btn-black{
    background: $background;
    color: $gold;
    text-align: center;
    width: 100%;
    padding: 12px 20px;
    border: 2px solid transparent;
    &:hover{
      background: transparent;
      color: $background;
      border-color: $background;
    }
  }
}