@import "variables";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "vendor/bower_components/lightgallery/src/sass/lightgallery";


$textColor: #363635;
$gold: #b38d5e;
$lightGold: #f7e1a8;
$orange: #e4b658;
$brown: #785724;
$backgroundForm: #d59d6c;
$background: #1c1711;
$backgroundSearch: #af8258;
$internaTextColor: #534741;
$error: #E47F58;


@mixin resize($device){
  @if $device == large{
    @media only screen and(min-width: 1681px){
      @content;
    }
  }
  @else if $device == tablet{
    @media only screen and (max-width: 992px) and (min-width: 768px){
      @content;
    }
  }
  @else if $device == mobile{
    @media only screen and (max-width: 767px) {
      @content;
    }
  }
  @else if $device == mediumdesktop{
    @media only screen and (min-width: 1400px) and (max-width: 1680px){
      @content;
    }
  }
}
@import "fonts";
@import "buttons";
@keyframes lightEffect {
  0%   {top: 0; left: 0;}
  25%  {top: 10px; left: 10px;}
  50%  {top: 0; left: 0;}
  100% {top: 10px; left: 10px;}
}
html,
body{
  height: 100%;
  position: relative;
  font-family: "Averta";
}
header{
  background: $background;
  height: 100px;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: "Adam";
  .navbar-default{
    text-transform: uppercase;
    font-size: 14px;
    background: transparent;
    border: 0;

    @media only screen and (max-width: 1200px){
      font-size: 13px;
    }

    .navbar-toggle{
      border: 0;
      margin-top: 20px;
      margin-right: 0;
      .icon-bar{
        background: $gold;
        transition: transform 0.3s linear;
      }
      &:hover,
      &:active,
      &:focus{
        background: transparent;
      }
    }
    .navbar-nav{
      opacity: 0;
      transition: opacity 0.5s linear, height 0.5s linear;
      li{
        padding-top: 35px;

        a{
          color: $gold;
          letter-spacing: 3px;

          @media only screen and (max-width: 1200px){
            letter-spacing: 1px;
          }

          &:hover,
          &:active,
          &:focus{
            color: $orange;
            background: transparent;
          }
        }

        &.dropdown{
          background: transparent;
          transition: all 0.5s linear;
          &:hover{
            background: transparent;
            .dropdown-menu{
              max-height: 9999px;
              display: block;
            }
          }
          &:active,
          &:focus{
            background: transparent;
          }
          &.open{
            a{
              background: transparent;
              color: $gold;
            }
          }

          .dropdown-menu{
            background-color: $background;
            display: none;
            max-height: 0;
            overflow: hidden;
            height: auto;
            transition: 0.8s;

            li{
              padding-top: 25px;

              @media only screen and(max-width: 1200px){
                padding-top: 15px;
              }

            }
          }

          &.musicos {
            .dropdown-menu{
              width: 400px;

              li {
                display: inline-block;
                width: 50%;
                padding: 10px;
                text-align: left;

                &:nth-child(odd) {
                  float: left;
                }

                &:nth-child(even) {
                  float: right;
                }
              }
            }
          }
        }
      }

      > li {
        &:nth-child(4){
          margin-left: 250px;

          @media only screen and (max-width: 1200px){
            margin-left: 193px;
          }
        }
      }
    }
    .navbar-brand{
      position: absolute;
      width: auto;
      left: 50%;
      margin-left: -63px !important;
      top: 10px;
      text-align: center;
      padding-top: 0;
      z-index: 1;
      .logo{
        width: auto;
        height: 70px;
        margin: 0 auto;
        transition: all 0.5s linear;
        position: relative;
        .cls-1 {
          fill: #ceb593;
          transition: all 0.5s linear;
        }
        .cls-3 {
          fill: #b28e5f;
          transition: all 0.5s linear;
        }
      }
    }
    .navbar-right{
      opacity: 0;
      transition: opacity 0.5s linear;
      li{
        display: inline-block;
        padding-top: 35px;
        a{
          padding: 10px 5px;
          color: #60563b;
          &:hover,
          &:active,
          &:focus{
            background: transparent;
            color: $orange;
          }
        }
      }
    }
  }
  &.fixed-header{
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: initial;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    .navbar-default{
      .navbar-nav{
        opacity: 1;
      }
      .navbar-right{
        opacity: 1;
      }
      .navbar-brand{
        margin-left: -86px !important;
        .logo{
          width: auto;
          height: 80px;
          top: 0;
          .cls-1{
            opacity: 0;
          }
        }
        &:hover{
          .logo{
            top: -17px;
            .cls-1{
              opacity: 1;
            }
            .cls-3{
              opacity: 0;
            }
          }
        }
      }
    }
  }
  @include resize(mobile){
    height: 80px;
    .navbar-default{
      .navbar-brand{
        .logo{
          height: 55px;
        }
      }
    }
    &.fixed-header{
      .navbar-default{
        .navbar-brand{
          .logo{
            height: 65px;
            .cls-1{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  @include resize(tablet){
    .navbar-nav,
    .navbar-right{
      display: none;
    }
    .navbar-header{
      float: right;
    }
    .navbar-toggle{
      display: block;

    }
  }
}
a,
button{
  outline: none !important;
  transition: all 0.3s linear;
}
.main-wrapper{
  position: relative;
  height: 100%;

  .breadcrumb-wrap{
    background: #25201a;
    margin-top:100px;
    
    .breadcrumb{
      background: none;
      margin: 0;

      li {
        a {
          color: #a07e52;
        }
      }
    }

    @media screen and (max-width: 768px){
      margin-top: 80px;
    }
  }
  
}


.player{
  height: 80px;
  width: 110px;
  border-top-left-radius: 45%;
  border-bottom-left-radius: 45%;
  border: 1px solid $gold;
  position: fixed;
  z-index: 1;
  top: 30%;
  right: 0;
  color: $gold;
  font-family: "Adam";
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0;
  background: transparent;
  
  .icon{
    background-image: url(/assets/site/images/music.svg);
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    display: block;
    margin-left: 40px;
    margin-bottom: 5px;
  }
}
.playlist{
  position: fixed;
  height: 100%;
  width: 500px;
  background: $background;
  right: -500px;
  z-index: 1;
  transition: all 0.5s linear;
  padding: 0 25px;
  color: $gold;
  top: 0;

  .jp-gui{
    background: #1c1711;
    z-index: 1;
    position: relative;
    padding: 6px;
  }
  .jp-jplayer{
    display: none !important;
  }
  .jp-interface{
    //Div que agrupa os controladores
    .jp-controls-holder{
      float: none;
      clear: both;
      margin: 30px 0 20px;
      width: 100%;
      position: relative;
      border: 1px solid $gold;
      padding: 25px 35px;
      border-radius: 40px;
      .logo{
        background-image: url(/assets/site/images/music.svg);
        background-repeat: no-repeat;
        height: 25px;
        width: 30px;
        display: inline-block;
      }
    }
    //Barra de Progresso
    .jp-duration{
      float: left;
    }
    .jp-current-time{
      float: right;
    }
    .jp-progress{
      .jp-seek-bar{
        width: 100%;
        height: 6px;
        border-radius: 6px;
        background: $gold;
        cursor: pointer;
        position: relative;
        .jp-play-bar{
          background: $background;
          height: 5px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
    //Controladores de Previous, next, play e stop
    .jp-controls{
      display: inline-block;
      .jp-previous{
        &:before{
          content: "\f048";
        }
      }
      .jp-next{
        &:before{
          content: "\f051";
        }
      }
      .jp-play{
        &:before{
          content: "\f04b";
        }
      }
      .jp-pause{
        &:before{
          content: "\f04c";
        }
      }
    }
    //Buttons Volume
    .jp-volume-controls{
      width: 40%;
      position: absolute;
      right: 40px;
      display: inline-block;
      .jp-mute{
        float: left;
        width: 10px;
        &:before{
          content: "\f026";
        }
      }
      .jp-volume-max{
        float: right;
        width: 10px;
        &:before{
          content: "\f028";
        }
      }
      .jp-volume-bar{
        height: 4px;
        background: #000;
        border-radius: 3px;
        margin-left: 5px;
        clear: both;
        width: calc(100% - 40px);
        display: inline-block;
        cursor: pointer;
        position: relative;
        z-index: 1;
      }
      .jp-volume-bar-value{
        background: $gold;
        height: 4px;
      }
    }
    //Formatação de todos buttons de controle
    button{
      background: transparent !important;
      border: 1px solid $gold !important;
      overflow: hidden;
      //text-indent: -9999px;
      height: 20px;
      &:before{
        font: normal normal normal 14px/1 FontAwesome;
      }

    }
  }
  .jp-playlist{
    clear: both;
    position: absolute;
    top: 0;
    height: 100%;
    padding-top: 150px;
    overflow-y: scroll;
    width: 100%;

    ul{
      list-style: none;
      padding: 0;
      li{
        margin: 10px 0;
        font-size: 13px;
        .jp-artist{
          display: block;
          font-size: 11px;
        }
        a{
          color: $lightGold;
          &:hover,
          &:active,
          &:focus{
            text-decoration: none;
            opacity: 0.8;
            .jp-artist{
              opacity: 0.8;
            }
          }
        }
        &.jp-playlist-current{
          a{
            color: $gold;
          }
        }
      }
    }
  }
  .hide-player{
    width: 40px;
    height: 40px;
    color: $gold;
    border-radius: 100%;
    position: absolute;
    left: 0;
    background: #000;
    border: 0;
    top: 50%;
  }
}

.sect{
  position: relative;
  height: 100%;
  font-size: 15px;
  &.main-sect{
    background-image: url(/assets/site/images/bg-home.jpg);
    background-size: cover;
    background-position: center;
    overflow: hidden;
    &:before{
      content: '';
      position: absolute;
      z-index: 0;
      background-image: url(/assets/site/images/light-effect.png);
      background-repeat: repeat;
      width: 80%;
      height: 70%;
      top: 0;
      left: 0;
      opacity: 0;
    }
    &.animated{
      &:before{
        opacity: 1;
        animation: lightEffect;
        animation-duration: 5s;
        animation-iteration-count: infinite;
      }
    }
    .container,
    .row,
    .col-xs-12{
      height: 100%;
    }
    .text-banner{
      color: #fff;
      position: relative;
      text-align: center;
      top: 45%;
      font-size: 45px;
      text-transform: uppercase;
      line-height: 45px;
      font-family: "Adam";
      letter-spacing: 15px;
      height: 100%;
      small{
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: none;
        display: block;
        font-family: "Averta";
        margin-top: 15px;
      }
    }


    @include resize(mobile){
      .text-banner{
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 10px;
        small{
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 5px;
        }
      }
    }
    @include resize(tablet){
      .player,
      .playlist{
        display: none;
      }
    }
  }
  &.events-sect{
    padding-top: 90px;
    text-align: center;
    .description{
      font-size: 15px;
      letter-spacing: 0.5px;
      color: $textColor;
    }
    //Linha
    &:before{
      content: '';
      width: 0;
      position: absolute;
      height: 1px;
      background: $orange;
      top: 30%;
      left: 40%;
      transition: width 1s linear;
    }
    .item-wrapper{
      cursor: pointer;
      height: 600px;
      margin: 100px 0;
      width: 50%;
      background-size: cover;
      background-position: center;
      position: relative;
      display: inline-block;
      float: left;
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($lightGold, 0.7);
        opacity: 0;
        transition: opacity 0.7s linear;
      }
      &.corporativo{
        background-image: url(/assets/site/images/events-img-2.jpg);
      }
      &.casamento{
        background-image: url(/assets/site/images/casamento.jpg);
      }
      .title{
        font-size: 25px;
        font-family: "Adam";
        text-transform: uppercase;
        color: #22160a;
        letter-spacing: 5px;
        line-height: 25px;
        margin: 15px 0;
      }
      .item-content{
        opacity: 0;
        transition: opacity 0.7s linear;
        position: relative;
        z-index: 1;
        top: 240px;
        .btn{
          margin-top: 25px;
        }
      }
      &:hover{
        .item-content{
          opacity: 1;
        }
        &:after{
          opacity: 1;
        }
      }
    }
    @include resize(large){
      &:before{
        top: 22%;
      }
    }
    @include resize(mobile){
      padding-top: 20px;
      height: auto;
      &:before{
        top: initial;
        left: 10%;
        bottom: 65%;
      }
      .description{
        font-size: 14px;
        line-height: 22px;
        margin: 30px 0;
      }
      .item-wrapper{
        height: 300px;
        margin: 30px 0 0;
        width: 100%;
        float: none;
        &:after{
          opacity: 1;
        }
        .item-content{
          top: 20px;
          opacity: 1;
        }
      }
    }
    @include resize(tablet){
      height: auto;
      &:before{
        top: 35%;
      }
      .item-wrapper{
        margin: 30px 0 0;
        height: 450px;
        width: 100%;
        float: none;
        &:after{
          opacity: 1;
        }
        .item-content{
          top: 150px;
          opacity: 1;
        }
      }
    }
  }
  &.about-sect{
    padding: 90px 0;
    text-align: center;
    height: auto;
    .section-title{
      color: $brown;
      margin: 15px 0;
    }
    //Linha
    &:before{
      content: '';
      width: 0;
      position: absolute;
      height: 1px;
      background: $orange;
      bottom: 10%;
      left: 40%;
      transition: width 1s linear;
    }
    p{
      font-size: 15px;
      letter-spacing: 1px;
      color: $textColor;
    }
    @include resize(mobile){
      padding: 25px 0;
      margin-bottom: 50px;
      p{
        font-size: 14px;
      }
      &:before{
        left: 10%;
        bottom: -20px;
      }
    }
  }
  &.instrumentos-sect{
    color: #fff;
    font-size: 15px;
    text-align: center;
    .content{
      padding-top: 30px;
      position: relative;
      z-index: 1;
    }
    .section-title{
      color: $lightGold;
      margin: 0 0 15px;
    }
    &:before{
      content: '';
      width: 0;
      position: absolute;
      height: 1px;
      background: $orange;
      top: 22%;
      left: 40%;
      transition: width 1s linear;
      z-index: 2;
    }
    #backgroundCarousel{
      height: 100%;
      position: absolute;
      top: 0;
      .owl-stage-outer,
      .owl-stage{
        height: 100%;
      }
      .owl-item{
        height: 100%;
        &.active{
          .item{
            height: 100%;
            .content{
              opacity: 1;
            }
          }
        }
      }
      .item{
        padding-bottom: 20px;
        background-color: #261a0c;
        background-position: center;
        background-size: cover;
        height: 100%;
        &:after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(/assets/site/images/bg-instrumentos.jpg);
          background-size: cover;
          background-position: center;
          opacity: 0.07;
        }
        .container,
        .row,
        .h100{
          height: 100%;
          position:relative;
        }
        .content{
          padding-top: 0;
          opacity: 0;
          transition: opacity 0.5s linear;
          position: absolute;
          margin-top: 0;
          line-height: 25px;
          color: $lightGold;
          font-size: 15px;
          z-index: 2;
          width: 100%;
          bottom: 0;
          left: 0;
          .nav{
            li{
              display: inline-block;
              position: relative;
              text-transform: uppercase;
              font-size: 18px;
              font-family: "Adam";
              a{
                color: $gold;
                padding: 10px 25px;
                &:hover,
                &:focus,
                &:active{
                  color: $lightGold;
                  text-decoration: none;
                  background: transparent;
                }
              }
              &:before{
                content: '•';
                position: absolute;
                left: 0;
                top: 10px;
              }
            }
          }
        }
      }
    }
    #instrumentosCarousel{
      margin-top: 20px;
      height: 330px;
      .owl-stage-outer,
      .owl-stage{
        height: 100%;
      }
      .owl-item{
        height: 100%;
        &.center{
          .item{
            img{
              opacity: 1;
            }
            &:after{
              opacity: 1;
            }
          }
        }
      }
      .item{
        position: relative;
        height: 100%;
        vertical-align: middle;
        transition: all 0.5s linear;
        img{
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          transition: all 0.3s;
          opacity: 0.4;
          margin: 0 auto;
        }
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
          opacity: 0;
          top: 0;
          left: 0;
          z-index: -1;
          transition: opacity 1s linear;
        }
        &.caixa{
          &:after{
            background-image: url(/assets/site/images/effect-caixa.png);
          }
        }
        &.trompete{
          &:after{
            background-image: url(/assets/site/images/effect-trompete.png);
          }
        }
        &.violino{
          &:after{
            background-image: url(/assets/site/images/effect-violino.png);
          }
        }
      }
      .owl-nav{
        font-size: 60px;
        color: $lightGold;
        width: 45%;
        position: absolute;
        top: 160px;
        left: 27.5%;
        .owl-prev{
          float: left;
        }
        .owl-next{
          float: right;
        }
      }
    }
    @include resize(large){
      height: 940px;
      .content{
        padding-top: 65px;
      }
      #backgroundCarousel{
        .item{
          .content{
            bottom: 50px;
          }
        }
      }
      #instrumentosCarousel{
        margin-top: 30px;
        height: 500px;
        .owl-item{
          .item{
            img{
              margin: 30px auto;
            }
          }
          &.center{
            .item{
              transform: scale(1.1);
            }
          }
        }
        .owl-controls{
          top: 220px;
        }
      }
    }
  }
  &.estilos-sect{
    text-align: center;
    height: auto;
    padding-top: 90px;
    &:before{
      content: '';
      width: 0;
      position: absolute;
      height: 1px;
      background: $orange;
      top: 10%;
      left: 40%;
      transition: width 1s linear;
      z-index: 2;
    }
    &:after{
      background-image: url(/assets/site/images/bg.jpg);
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      position: absolute;
      opacity: 0.4;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: '';
      z-index: -1;
    }
    p{
      margin: 10px 0 0;
    }
    .item-wrapper{
      position: relative;
      margin: 120px 0;
      text-align: center;
      .description{
        font-size: 16px;
        color: $brown;
        margin: 10px 0 20px;
      }
    }
    @include resize(mobile){
      padding-top: 50px;
      color: #fff;
      background: $background;
      &:before,
      &:after{
        display: none;
      }
      .item-wrapper{
        margin: 50px 0 0;
      }
    }
    @include resize(tablet){
      background: $background;
      &:before{
        top: 25%;
      }
    }
  }
  &.clientes-sect{
    height: auto;
    padding: 50px 0;
    .owl-carousel{
      margin: 50px 0;
      .item{
        img{
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
  &.blog-sect{
    color: $lightGold;
    text-align: center;
    background-color: $background;
    padding-top: 85px;
    height: auto;
    p{
      margin: 20px 0 0;
    }
    .blog-wrapper{
      .current-img{
        margin: 50px 0 75px;
      }
      .blog-items{
        margin: 50px 0;
        position: relative;
        list-style: none;
        text-align: left;
        .info{
          color: $brown;
          font-size: 8px;
        }
        .content{
          font-size: 14px;
          color: #c7b299;
          letter-spacing: 1px;
          a{
            text-transform: uppercase;
            color: #fff;
            pointer-events: none;
            cursor: default;
            &:hover{
              color: $gold;
              text-decoration: none;
            }
            &:after{
              content: '';
            }
          }
        }
        li{
          cursor: pointer;
          margin: 0 0 40px;
          opacity: 0.3;
          position: relative;
          transition: all 0.5s linear;
          &:last-child{
            margin-bottom: 0;
          }
          &.active{
            opacity: 1;
            .content{
              a{
                pointer-events: auto;
                cursor: pointer;
              }
            }
            &:before{
              content: '';
              height: 100%;
              position: absolute;
              width: 10px;
              background: $gold;
              top: 0;
              left: -40px;
            }
          }
        }
      }
    }
  }
  &.parceiros-sect{
    text-align: center;
    padding-top: 60px;
    height: auto;
    .icon{
      font-size: 50px;
      display: block;
    }
    .icon-wrapper{
      text-align: center;
      color: rgba($gold,0.7);
      cursor: pointer;
      margin: 75px 0;
      height: 110px;

      a{
        color: rgba($gold,0.7);
      }

      .title{
        font-size: 18px;
        font-family: "Adam";
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 15px 0;
        display: inline-block;
        position: relative;

        &:before{
          content: '';
          height: 5px;
          background: $gold;
          position: absolute;
          width: 100%;
          bottom: -12px;
          left: 0;
          opacity: 0;
          transition: opacity 0.4s linear;
        }
      }

      &:hover{
        color: $gold;
        .title{
          &:before{
            opacity: 1;
          }
        }
      }
    }
  }
  &.content-sect{
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 130%;
    .container,
    .row,
    .h100{
      height: 100%;
    }

    .section-title{
      text-align: left;
      margin-bottom: 15px;
      padding-top: 60px;
      color: $backgroundForm;
    }

    .content {
      line-height: 23px;
      text-align: left;

      .section-inner {
        margin-bottom: 60px;
      }
    }
    p{
      margin-bottom: 20px;
    }
    ul{
      list-style: none;
      padding-left: 0;
      li{
        &:before{
          content: '-';
        }
      }
    }
    .section-subtitle{
      margin-top: 80px;
    }
    .btn-nocover{
      //margin-top: 90px;
    }
    .formInterna{
      position: absolute;
      width: 100%;
      background: rgba($backgroundForm, 0.8);
      height: 0;
      overflow: hidden;
      transition: height 0.5s linear, opacity 0.5s linear;
      .form-title{
        padding: 150px 45px 0;
      }
      .form{
        padding: 0 45px 0;
      }
      .form-group{
        transition: all 0.5s linear;

        .form-control{
          &::-webkit-input-placeholder {
            color: $backgroundForm;
          }

        }
        .input-check{
          overflow: hidden;
          height: auto;
          max-height: 0;
          position: relative;
          z-index: 1;
          transition: height 0.5s linear;
          width: 100%;
          display: inline-block;

          &.show-input{
            max-height: 999px;
          }
        }
        label.select-label{
          &:after{
            top: 1px;
          }
        }
      }
    }
    &.show-form{
      .formInterna{
        height: 100%;
        //height: auto;

        @media only screen and (max-width: 768px){
          overflow-x: hidden;
          overflow-y: scroll;
        }
      }

    }

    @include resize(large){
      height: 100%;

      .section-title{
        padding-top: 200px;
      }

    }

    @media only screen and (max-width: 992px){
      background-position: center;
      height: auto;
      background-size: 150% 360px;
      padding-bottom: 40px;
      text-align: center;
      margin-top: 0;
      font-size: 14px;

      .section-subtitle{
        margin-top: 50px;
      }
      .section-title{
        text-align: left;
        padding-top: 50px !important;

      }
      .btn-nocover{
        font-size: 13px;
        letter-spacing: 1px;
        margin: 0 auto;
      }
      .formInterna{
        position: fixed;
        height: auto;
        max-height: 0;
        overflow: hidden;
        z-index: 8;
        padding: 0 15px;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        background: rgba($backgroundForm, 0.9);
        .close-btn{
          background: transparent;
          border: 0;
          position: absolute;
          top: 90px;
          right: 0;
          z-index: 1;
          color: #fff;
        }
        .form-title{
          padding: 80px 15px 0;
        }
        .form-group{
          margin-bottom: 10px;
        }
        .form{
          padding: 0;
        }
      }
      &.show-form{
        .formInterna{
          max-height: 9999px;
          opacity: 1;
        }
      }
    }
    //@include resize(tablet){
    //  height: 100% !important;
    //}
  }
  &.sect-vejamais{
    text-align: center;
    height: auto;
    padding: 50px 0 ;
    &:before{
      content: '';
      width: 0;
      position: absolute;
      height: 1px;
      background: $orange;
      top: 25%;
      left: 40%;
      transition: width 1s linear;
    }
    @include resize(large){
      height: 100%;
      padding: 150px 0 0;
      &:before{
        top: 30%;
      }
    }
    .owl-carousel{
      margin-top: 90px;
    }
    .description{
      margin: 15px 0;
    }
    @include resize(mobile){
      &:before{
        left: 10%;
        top: 32%;
      }
    }
  }
  &.search-sect{
    background: $backgroundSearch;
    color: $lightGold;
    padding: 30px 0 0;
    text-align: center;
    .section-subtitle{
      letter-spacing: 10px;
      color: $lightGold;
    }
    table{
      width: 100% !important;
      border: 0 !important;
      font-size: 15px;
      transition: all 0.5s linear;
      thead,
      tfoot{
        display: none !important;
      }
      tr{
        background: transparent;
        border: 0;
        color: $lightGold;
        display: inline-block;
        width: calc(100% / 3);
        padding: 3px 0;
        td{
          border: 0;
          display: block;
          padding: 0;
          color: #e9ceb3;
          a{
            color: #e9ceb3;
            &:hover,
            &:focus,
            &:active{
              text-decoration: none;
              color: $lightGold;
            }
          }
        }
        .title{
          color: #fff;
        }
      }
    }
    .dataTables_wrapper{
      text-align: left;
      margin-top: 15px;
      .dataTables_filter{
        width: 100%;
        height: 45px;
        margin: 0 0 20px;
        input{
          width: 100%;
          margin: 0 ;
          border-radius: 0;
          height: 100%;
          border-color: $orange;
        }
        label{
          width: 100%;
          height: 100%;
          position: relative;
          &:before{
            font: normal normal normal 31px/1 FontAwesome;
            color: #c7b299;
            content: "\f002";
            position: absolute;
            right: 5px;
            top: 5px;
          }
        }
      }
      .dataTables_paginate{
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .paginate_button{
          padding: 10px 5px;
          a{
            background: transparent;
            color: #fff;
            outline: none;
            &:hover,
            &:active,
            &:focus{
              border-color: $lightGold;
            }
          }
          &:hover,
          &:active,
          &:focus{
            background: transparent;
            border-color: transparent;
            box-shadow: none;
            a{
              border-color: $lightGold;
              &:hover,
              &:active,
              &:focus{
                border-color: $lightGold;
              }
            }
          }
          &.active{
            a{
              border-color: $orange;
              &:active,
              &:focus,
              &:hover{
                border-color: $orange;
              }
            }
          }
        }
      }

    }
    @include resize(large){
      padding: 100px 0;
      table{
        tr{
          padding: 10px 0;
        }
      }
      .dataTables_wrapper{
        margin-top: 35px;
        .dataTables_filter{
          margin: 0 0 100px;
        }
      }
    }
    @include resize(mobile){
      height: auto;
      table{
        tr{
          width: 100%;
          display: block;
        }
      }
      .dataTables_wrapper{
        .dataTables_paginate{
          ul.pagination{
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .paginate_button{
              padding: 10px 0;
            }
          }
        }
      }
    }
    @include resize(tablet){
      height: auto;
      table{
        tr{
          width: 50%;
        }
      }
    }
  }
  &.loaded{
    //&:before{
    //  width: 20%;
    //}

    .section-header:after{
      //width: 20%;
    }

    @include resize(mobile){
      &:before{
        width: 80%;
      }
    }
  }
}
.portifolio-sect{
  margin-top: 85px;
  text-align: center;
  .nav{
    margin: 50px 0;
    li{
      display: inline-block;
      position: relative;
      text-transform: uppercase;
      font-size: 18px;
      font-family: "Adam";
      a{
        color: $gold;
        padding: 10px 25px;
        position: relative;
        &:hover{
          color: $orange;
          text-decoration: none;
          background: transparent;
        }
        &:before{
          content: '';
          position: absolute;
          bottom: 5px;
          width: 70%;
          left: 15%;
          background: $orange;
          height: 3px;
          opacity: 0;
          transition: opacity 0.3s linear;
        }
        &.active{
          color: $orange;
          background: transparent;
          &:before{
            opacity: 1;
          }
        }
      }
      &:before{
        content: '•';
        position: absolute;
        left: 0;
        top: 10px;
        color: $gold;
      }
    }
  }
  .galeria-wrapper{
    height: 700px;

    .item-galeria{
      display: inline-block;
      overflow: hidden;
      float: left;
      position: relative;
      width: 100%;
      height: calc(100% / 3);
      border-right: 5px solid #fff;
      border-bottom: 5px solid #fff;
      cursor: pointer;

      .item-description{
        font-size: 15px;
        text-align: center;
        padding: 50px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        letter-spacing: 3px;
        color: #363635;
        transition: opacity 0.5s linear;
        .title{
          color: $background;
          font-family: "Adam";
          font-size: 18px;
          text-transform: uppercase;
          letter-spacing: 5px;
          line-height: 18px;
        }
        .location{
          margin: 10px 0 40px;
        }
      }
      &:after{
        position: absolute;
        background: $gold;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        opacity: 0;
        transition: opacity 0.5s linear;
      }
      img{
        width: 120%;
        height: auto;

        @media only screen and (max-width: 1200px){
          width: auto;
          height: 100%;
        }
      }
      &:hover{
        &:after{
          opacity: 0.7;
        }
        .item-description{
          opacity: 1;
        }
      }
      a{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .col-left{
      width: 25%;
      float: left;
      height: 100%;
    }
    .col-center{
      width: 50%;
      float: left;
      height: 100%;
      .item-galeria{
        width: 50%;
        &.item-destaque{
          width: 100%;
          height: calc(100% / 3 * 2);
        }
      }
    }
    .col-right{
      width: 25%;
      float: right;
      height: 100%;
      .item-galeria{
        border-right: 0;
      }
    }
    @include resize(large){
      height: 910px;
    }
  }


  &.loading {
    .galeria-wrapper {
      position: relative;

      &:after {
        content: '';
        display: block;
        height: 80px;
        width: 80px;
        background: url("../images/spin.gif") no-repeat;
        z-index: 1;
        position: absolute;
        background-size: cover;
        left: 50%;
        top: 20%;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.71);
        z-index: 1;
      }
    }



    .loader {
      display: block;
      position: absolute;
    }
  }
}
.depoimentos-wrapper{
  background-image: url(/assets/site/images/bg-depoimentos.jpg);
  background-size: cover;
  background-position: center;
  height: 670px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 50%;
    top: 10%;
    background: $gold;
    width: 1px;
    height: 0;
    transition: height 0.7s linear;
  }
  .logo{
    background-image: url(/assets/site/images/logo-notext.svg);
    background-repeat: no-repeat;
    background-position: 8px 3px;
    background-size: 65%;
    background-color: #000;
    z-index: 1;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 45%;
    left: calc(50% - 25px);
    border: 2px solid $gold;
    border-radius: 100%;
    opacity: 0;
    transition: opacity 0.7s linear;
  }
  &.loaded{
    &:before{
      height: 80%;
    }
    .logo{
      opacity: 1;
    }
  }
  .item-wrapper{
    position: relative;
    margin: 120px 0;
    text-align: center;
    .description{
      font-size: 16px;
      color: $brown;
      margin: 10px 0 20px;
    }

    img{
      border-radius: 100%;
    }
    p{
      color: #dfd6b9;
      letter-spacing: 1px;
      margin: 50px 0 0;
    }
    .name{
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Adam";
      color: $orange;
      letter-spacing: 5px;
      margin-top: 70px;
      .locate{
        display: block;
      }
    }
  }
}
footer{
  background: $background;
  padding: 55px 0 90px;
  color: #736357;
  font-size: 12px;
  letter-spacing: 0.5px;
  position: relative;
  p{
    margin: 25px 0 15px;
  }
  a{
    color: #736357;
    &:hover,
    &:active,
    &:focus{
      text-decoration: none;
      color: $gold;
    }
  }
  ul{
    padding-left: 0;
    list-style: none;
    margin: 0 0 15px;
    font-size: 14px;
    li{
      margin: 8px 0;
      position: relative;
      &:before{
        content: '-';
      }
    }
  }
  .form{
    margin-top: 15px;

    .btn-nocover {
      color: #ffffff !important;
      background: #b38d5e;
      
      
      &:hover {
        color: #b38d5e !important;
        background: transparent !important;
        border: 1px solid $gold;
      }
    }
  }
  .form-group{
    position: relative;
    .form-control{
      border-radius: 0;
      border-color: $gold;
      height: 45px;
    }
    textarea.form-control{
      height: 100px;
    }
    .btn{
      border-color: $gold;
      color: $gold;
      width: 100%;
      padding: 10px 25px;
    }
  }
  .credits{
    color: #555555;
    font-size: 14px;
    background: #242424;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 50px;
    left: 0;
    z-index: 1;
    padding-top: 15px;
    a{
      color: #555555;
      &:hover{
        color: $gold;
        text-decoration: none;
      }
    }
  }
  @include resize(mobile){
    padding-top: 15px;
    .credits{
      font-size: 10px;
      padding: 15px 0;
      height: auto
    }
  }
}
.form-group {
  position: relative;
  .form-control {
    border-radius: 0;
    border-color: $backgroundForm;
    display: block;
    &.error{
      border-color: $error;
    }
    &:not(textarea){
      height: 45px;
    }
    &.datepicker,
    &.location-select{
      margin: 0;
      transition: height 0.3s linear;
      cursor: pointer;
      color: $backgroundForm;
    }
  }
  label{
    color: #efebe6;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    &.select-label{
      position: absolute;
      width: 100%;
      &:after{
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f078";
        display: block;
        position: absolute;
        top: 11px;
        right: 0;
        padding-top: 10px;
        padding-left: 15px;
        background: $background;
        color: $gold;
        line-height: 26px;
        border-radius: 0;
        pointer-events: none;
        width: 45px;
        height: 43px;
        cursor: pointer;
      }
    }
    &.error{
      position: absolute;
      right: 0;
      top: 0;
      color: $error;
    }
  }
  .checkbox-item{
    width: 20px;
    position: relative;
    display: inline-block;
    &:nth-of-type(2){
      margin-left: 15px;
    }
    label {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      background: #fcfff4;
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      border-radius: 0;
      box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba($backgroundForm,0.5);
      &:after {
        content: '';
        width: 12px;
        height: 7px;
        position: absolute;
        top: 5px;
        left: 4px;
        border: 3px solid $backgroundForm;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
    }
    input[type=checkbox] {
      visibility: hidden;
      &:checked + label:after {
        opacity: 1;
      }
    }
  }
}
.interna{
  .sect{
    color: $internaTextColor;
    letter-spacing: 2px;
    background-size: cover;
  }
  &.eventos{
    .sect{
      &.content-sect{
        height: 150%;
        @include resize(large){
          height: 110%;
          background-position: bottom center;
        }
        .section-title{
          padding-top: 60px;
        }
        .section-subtitle{
          margin-top: 50px;
        }
        .btn-nocover{
          margin-top: 25px;
        }
        .formInterna{
          .form-group{
            &:nth-of-type(6){
              width: 60%;
              display: inline-block;
            }
            &:nth-of-type(7){
              width: 38%;
              float: right;
              display: inline-block;
              .input-check{
                width: 100%;
              }
            }
          }
        }
      }
    }
    .portifolio-sect{
      padding-top: 50px;
      .galeria-wrapper{
        margin-top: 50px;
      }
    }
  }
  &.contato{
    .sect{
      @media only screen and (max-width: 768px){
        #map-canvas {
          display: none;
        }
      }

      &.content-sect{
        color: #fff;
        padding: 0;

        .container {
          padding: 0;
          overflow: hidden;
        }

        a{
          color: #fff;
          word-wrap: break-word;

          &:hover{
            text-decoration: none;
            color: $lightGold;
          }
        }

        .content{

          @media only screen and (max-width: 768px){
            padding: 50px 25px 0;
          }

          padding: 180px 45px 0;
        }
        .section-title{
          color: #fff;
          padding-top: 0;
        }
        .formInterna{
          position: relative;

          @media only screen and (max-width: 768px){
            background: rgb(28, 23, 17);

          }

          .form-title{
            padding-top: 50px;
          }
        }
      }
    }

    @media only screen and (max-width: 768px){
      .form-footer{
        display: none;
      }
    }
  }
}
.section-header {
  &:after{
    content: '';
    width: 20%;
    position: relative;
    display: block;
    height: 1px;
    background: #e4b658;
    margin-top: 26px;
    left: 40%;
    transition: width 1s linear;
    z-index: 2;
  }
}

//Padrões de Títulos
.section-title{
  font-family: "Adam";
  font-size: 30px;
  text-transform: uppercase;
  margin: 0;
  color: $gold;
  text-align: center;
  letter-spacing: 5px;
  small{
    color: inherit;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 5px;
    display: block;
  }
  @include resize(mobile){
    font-size: 18px;

  }
}
.section-subtitle,
h2{
  font-size: 18px;
  color: $orange;
  font-family: "Adam";
  letter-spacing: 4px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 7px;

  .help-block{
    color: $orange;
  }
}
.category-title{
  font-size: 20px;
  font-family: "Adam";
  color: #fff;
}
.item-title{
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Adam";
  color: $gold;
  letter-spacing: 5px;
  margin: 15px 0;

  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  @include resize(tablet){
    font-size: 17px;
  }
}
.post-title{
  font-size: 20px;
  color: $gold;
  text-transform: uppercase;
  font-family: "Adam";

}
.footer-title{
  font-size: 16px;
  font-family: "Adam";
  text-transform: uppercase;
  color: $gold;
  letter-spacing: 2px;
  margin: 0;
  padding-top: 15px;
}
.form-title{
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Adam";
  color: #f0ddc8;
  letter-spacing: 5px;
  margin: 20px 0;
}
div.pp_overlay{
  background: $gold !important;
  opacity: 0.65 !important;
}
@include resize(mobile){
  .pull-right,
  .pull-left{
    float: none !important;
  }
}
#map-canvas{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  .section-subtitle{
    margin: 0;
    font-size: 15px;
    line-height: 15px;
  }
}
@include resize(mobile){
  .owl-carousel{
    .owl-dots{
      text-align: center;
      margin: 30px 0;
      .owl-dot{
        display: inline-block;
        width: 40px;
        height: 5px;
        background: #ccc;
        margin: 0 5px;
        vertical-align: bottom;
        &.active{
          background: $gold;
          height: 8px;
        }
      }
    }
  }
}

//LIGHTBOX
.lv_overlay{
  background-color: $gold !important;
}
.lv_window{
  background-color: #fff;
  position: relative;
  .lv_skin{
    display: none !important;
  }
  .lv_title_caption{
    color: #000;
  }
}
.lv_thumbnails{
  .lv_thumbnails_slide{
    left: 0 !important;
  }
}
.md-scroll-mask { position: initial;}

//Mobile nav
.mobile-nav{
  height: 100%;
  overflow-y: scroll;
  width: 70%;
  position: fixed;
  top: 0;
  right: -70%;
  display: none;
  background: $background;
  z-index: 10;
  transition: all 0.5s linear;

  .navbar{
    padding: 15px 10px;

    li{
      position: relative;
      color: $gold;
      font-family: "Adam";
      font-size: 14px;
      padding: 7px 15px;
      letter-spacing: 1px;

      .caret{
        transition: 0.3s;
      }

      a{
        color: $gold;
        padding: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "Adam";

        &:active,
        &:focus,
        &:hover{
          background: $background;
          color: $gold;
        }
      }

      &.dropdown{
        .dropdown-menu{
          display: block;
          position: relative;
          overflow: hidden;
          max-height: 9999px;
          height: 0;
          transition: all 0.5s linear;
          background: $background;
          font-size: 13px;
          margin: 0;
          width: 100%;
          border: 0;
          padding: 0 15px 10px;
          box-shadow: none;
        }
        &.open{
          .dropdown-menu{
            height: auto;
          }
          .caret{
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

html {
  &.show-playlist{

    .playlist{
      right: 0;
      .hide-player{
        left: -17px;
      }
    }
  }

  &.with-fixed-header{

    .playlist {
      padding-top: 100px;

      .jp-playlist{
        padding-top: 263px;
      }
    }
  }

  &.fake-gallery-running {
    overflow: hidden;
  }
}

@include resize(mobile){
  .main-wrapper{
    right: 0;
    transition: all 0.5s linear;
  }
  .mobile-nav{
    display: block;
  }
  .show-menu{
    overflow: hidden;

    .mobile-nav{
      right: 0;
    }
    .main-wrapper{
      right: 70%;
    }
    header{
      .navbar-default{
        .navbar-toggle{
          .icon-bar{
            transform: rotate(45deg);
            &:nth-of-type(2){
              opacity: 0;
            }
            &:nth-of-type(3){
              transform: rotate(135deg);
              position: absolute;
              top: 5px;
            }
          }
        }
      }
    }
  }

  .player,
  .playlist{
    display: none;
  }
}
@include resize(tablet){
  .main-wrapper{
    right: 0;
    transition: all 0.5s linear;
  }
  .mobile-nav{
    display: block;
  }
  .show-menu{
    .mobile-nav{
      right: 0;
    }
    .main-wrapper{
      right: 70%;
    }
    header{
      .navbar-default{
        .navbar-toggle{
          .icon-bar{
            transform: rotate(45deg);
            &:nth-of-type(2){
              opacity: 0;
            }
            &:nth-of-type(3){
              transform: rotate(135deg);
              position: absolute;
              top: 5px;
            }
          }
        }
      }
    }
  }
}

.fake-gallery-container {
  position: fixed;
  background: rgba(0,0,0,0.6);
  top: 0;
  overflow-y: scroll;
  left: 0;
  padding:30px;
  width: 100%;
  height: 100%;

  -webkit-transition: ease .2s;
  -moz-transition: ease .2s;
  -ms-transition: ease .2s;
  -o-transition: ease .2s;
  transition: ease .2s;

  opacity: 0;
  z-index: -999;

  .container {
    height: 100%;
  }

  // Loader
  .loader {
    position: relative;
    top: 50%;
    margin: 60px auto 0;
    display: none;

    &.loading{
      display: block;
    }
  }

  .fake-gallery-main-title {
    font-size: 30px;
    margin-bottom: 30px;
    color: #FFF;
  }

  // On Show Gallery
  &.show {
    opacity: 1;
    z-index: 999;
  }

  // The Playlist
  .fake-gallery-playlist {
    max-height: 999px;

    -webkit-transition: ease .2s;
    -moz-transition: ease .2s;
    -ms-transition: ease .2s;
    -o-transition: ease .2s;
    transition: ease .2s;

    .fake-gallery-video{
      margin-bottom: 30px;
      cursor: pointer;

      .fake-gallery-video-wrap{
        position: relative;


        .fake-gallery-video-thumbnail {
          overflow: hidden;
          
          img {
            -webkit-transition: ease .8s;
            -moz-transition: ease .8s;
            -ms-transition: ease .8s;
            -o-transition: ease .8s;
            transition: ease .8s;
          }
        }
        .fake-gallery-video-title {
          position: absolute;
          color: #FFF;
          bottom: 0;
          padding: 15px;

          display: block;
          width: 100%;
          left: 0;
          font-size: 12px;
          background: rgba(0, 0, 0, 0.84);
        }
      }

      // Actions
      &:hover {
          .fake-gallery-video-wrap {
            .fake-gallery-video-thumbnail {
              img {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
              }
            }
          }
      }
    }


  }

  // The Player
  .fake-gallery-player {
    opacity: 0;
    height: 0;
    position: relative;
    z-index: -999;

    .fake-gallery-player-close {
      position: absolute;
      right: -20px;
      top: -20px;
      background: #000;
      border: 2px solid #FFF;
      border-radius: 100%;
      color: #FFF;
      width: 40px;
      height: 40px;
      line-height: 40px;
      padding: 0;
    }

    .fake-gallery-frameset{
      width: 100%;
      height: 100%;
    }
  }

  // On Play video
  &.playing {
    // Hide Playlist
    .fake-gallery-playlist {

      animation: minimizePlaylist 1s linear;
      -webkit-animation-fill-mode: forwards;

      overflow: hidden;
      position: relative;
    }

    // Show player
    .fake-gallery-player {
      opacity: 1;
      z-index: 1;
      height: 80%;
    }
  }
}

@keyframes minimizePlaylist {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }

  100% {
    max-height: 0;
  }
}

.social-footer {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  text-align: center;
  
  li {
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    
    &:before {
      content: '';
    }

    a {
      font-size: 27px;
    }
  }
}

.monteicon {
  background: url('/assets/site/images/spritesheet.png') no-repeat;
  display: block;
  margin: 0 auto;

  &.monte-car {
    background-position: -8px -339px;
    width: 48px;
    height: 45px;
  }

  &.padre {
    background-position: -5px -259px;
    width: 53px;
    height: 57px;
  }

  &.celebrantes {
    background-position:  -4px -455px;
    width: 55px;
    height: 56px;
  }

  &.acessoria {
    background-position: -5px -525px;
    width: 54px;
    height: 45px;
  }

  &.bem-casados {
    background-position: -7px -386px;
    width: 49px;
    height: 55px;
  }

  &.foto-filmagem {
    background-position: -7px -205px;
    width: 50px;
    height: 41px;
  }

  &.dia-de-noiva {
    background-position: -5px -148px;
    width: 54px;
    height: 41px;
  }

  &.hotel {
    background-position: -5px -582px;
    width: 54px;
    height: 55px;
  }

  &.dj {
    background-position: -5px -79px;
    width: 54px;
    height: 55px;
  }

  &.buffet {
    background-position: -11px -11px;
    width: 41px;
    height: 53px;
  }

}